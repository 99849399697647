export function calculateTakeProfit(
  entryPrice: number,
  takePriceValue: string,
  isPriceUp: boolean,
  betValue: number,
  multiplierValue: number,
) {
  const numberTakePriceValue = parseFloat(takePriceValue.replace(/,/g, ''));
  const priceDifference = isPriceUp ? numberTakePriceValue - entryPrice : entryPrice - numberTakePriceValue;
  const validTakePrice = isPriceUp ? numberTakePriceValue > entryPrice : numberTakePriceValue < entryPrice;
  const takeProfit = (betValue * priceDifference * multiplierValue) / entryPrice;
  return validTakePrice ? takeProfit.toString() : '';
}

export function calculateTakePrice(
  entryPrice: number,
  takeProfitValue: string,
  isPriceUp: boolean,
  betValue: number,
  multiplierValue: number,
) {
  const numberTakeProfitValue = parseFloat(takeProfitValue.replace(/,/g, ''));
  const takePriceUp = (numberTakeProfitValue * entryPrice) / (betValue * multiplierValue) + entryPrice;
  const takePriceDown = entryPrice - ((numberTakeProfitValue / multiplierValue) * entryPrice) / betValue;
  const takePrice = isPriceUp ? takePriceUp : takePriceDown;
  if (takePrice <= 0) {
    return '0';
  }
  return !takePrice ? '' : takePrice.toString();
}

export function calculateCloseLoss(
  entryPrice: number,
  closePriceValue: string,
  isPriceUp: boolean,
  betValue: number,
  multiplierValue: number,
) {
  const numberClosePriceValue = parseFloat(closePriceValue.replace(/,/g, ''));
  const bustPrice = isPriceUp ? entryPrice - entryPrice / multiplierValue : entryPrice + entryPrice / multiplierValue;
  const withinRange = isPriceUp
    ? numberClosePriceValue <= entryPrice && numberClosePriceValue >= bustPrice
    : numberClosePriceValue >= entryPrice && numberClosePriceValue <= bustPrice;
  const loss = isPriceUp ? entryPrice - numberClosePriceValue : numberClosePriceValue - entryPrice;
  const calculatedLoss = (loss * betValue * multiplierValue) / entryPrice;
  return withinRange ? calculatedLoss.toString() : '';
}

export function calculateClosePrice(
  entryPrice: number,
  stopLossValue: string,
  isPriceUp: boolean,
  betValue: number,
  multiplierValue: number,
) {
  const numberStopLossValue = parseFloat(stopLossValue.replace(/,/g, ''));
  const lossFactor = (numberStopLossValue * entryPrice) / (betValue * multiplierValue);
  const closePriceCalculated = isPriceUp ? entryPrice - lossFactor : entryPrice + lossFactor;
  const bustPrice = entryPrice + ((isPriceUp ? -1 : 1) * entryPrice) / multiplierValue;

  const isNotValidCloseLoss =
    !numberStopLossValue ||
    (isPriceUp && closePriceCalculated < bustPrice) ||
    (!isPriceUp && closePriceCalculated > bustPrice);

  return isNotValidCloseLoss ? '' : closePriceCalculated.toString();
}

export function calculateBustPrice(entryPrice: number, multiplierValue: number, isPriceUp: boolean) {
  if (!multiplierValue) return 0;
  const bustPriceUp = entryPrice - (entryPrice / multiplierValue - entryPrice * 0.0004);
  const bustPriceDown = entryPrice + (entryPrice / multiplierValue + entryPrice * 0.0004);
  const bustPrice = isPriceUp ? bustPriceUp : bustPriceDown;
  return bustPrice;
}
